<template>
    <div class="payment_box">
        <h3 class="title">支持</h3>
        <div class="pay_box">
            <div class="item" v-for="(item, index) in payList" :key="index" :class="money === item && 'active'" @click="selectMoney(item)">
                {{item}}元
            </div>
        </div>
        <label for="inputMoney">
            <div class="inter hairline bottom">
                <input
                    id="inputMoney"
                    type="tel"
                    v-model="inputMoney"
                    @focus="hidePlaceholder"
                    @blur="showPlaceholder"
                    oninput="value=value.replace(/\D|^0/g,'')"
                    pattern="[0-9]*"
                    maxlength="6"
                    placeholder="支持金额（必填）"
                />
            </div>
        </label>
        <div v-if="isWx&&false" class="green_doctor" @click="isSelect = true">
            <p class="dec">
                提供住院管家、手术安排、远程会诊等<span>六项</span>超值健康服务，全方位解决您的健康难题，限时<span>3元</span>领取！
            </p>
            <div class="select-box">
               <i :class="isSelect ? 'select': ''" @click.stop="isSelect = !isSelect"></i>我已阅读并同意<span @click.stop="openDecPop('sixServe')">《绿医通道服务项目》、</span><span @click.stop="openDecPop('health')">《健康告知》</span>
            </div>
        </div>
        <label for="text" v-else>
            <div class="textarea hairline bottom">
                <h4>支持留言（选填）</h4>
                <input id="text" type="text" v-model="remark" placeholder="填写爱心留言">
            </div>
        </label>
        <button class="submit_btn" @click="goPay">确认支付</button>
        <agreementDoc v-if="showDec" :type="decType" @close="cloesDecPop" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { perCheck } from './api/api';
import pay from './api/pay';
import agreementDoc from './components/agreement-dec'
import { authChecker } from '@/common/js/mmcModule';
import { shareProj, tracking } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils';

import utils from "@/common/js/utils";
const params = utils.getRequestParams();
const pageKey = 'H5_JSB_PaymentPage';

export default {
    data() {
        return {
            showDec: false,
            decType: 'health',
            payList: [ 10, 20, 50, 100],
            money: 10,
            payment_type: 0,
            isFocus: false,
            inputMoney: '',
            remark: '',
            isSelect: false,
            isWx: false,
            payInit: false
        }
    },
    computed: {
        ...mapState(['jsbrnd']),
    },
    mounted() {
        const that = this;
        this.isWx = utils.is_weixin();
        authChecker.check({ notneedphone: 1 }, () => {
            that.getConfig();
            that.initShare();
        })

    },
    components: {
        agreementDoc
    },
    methods: {
        openDecPop(e) {
            this.decType = e;
            this.showDec = true;
        },
        cloesDecPop() {
            this.showDec = false;
        },
        initShare() {
            const { projuuid } = params;
            const shareParams = {
                projuuid,
                shareRecord: {
                    scene: pageKey,
                },
            };
            shareProj.init(shareParams);
        },
        hidePlaceholder() {
            this.isFocus = true;
            this.money = 0
        },
        showPlaceholder() {
            this.isFocus = false;
            if(this.inputMoney){
                this.money = 0
            }else{
                this.money = 10
            }
        },
        selectMoney(e) {
            this.money = e;
        },
        getConfig() {
            const { projuuid } = params;
            const that = this;
            perCheck({env: utils.is_weixin() ? 'wx':'h5', redirect_url: location.href, projuuid}).then(res => {
                if(res.code === 0) {
                    that.payInit = true;
                    document.title = res.data.project_name;
                    const { redirect_url, h5_payment_type, wx_payment_type} = res.data;
                    that.payment_type = utils.is_weixin() ? wx_payment_type : h5_payment_type
                    if(redirect_url && redirect_url !== ''){
                        window.location.replace(redirect_url);
                    }

                    this.reportData();
                }
            })
        },
        goPay() {
            if(!this.payInit) {
                alertMsg('初始化中，请稍后');
                return
            }
            const { projuuid } = params;
            const { money, inputMoney, payment_type, remark, isSelect } = this;
            if((money || inputMoney) <= 0) {
                alertMsg('请输入正确金额')
                return
            }
            pay({
                project_uuid: projuuid,
                amount: money || inputMoney,
                isSelect: utils.is_weixin() ? isSelect : false,
                pay_type: utils.is_weixin() ? 'wx':'h5',
                payment_type,
                anonymous: 0,
                comments: remark,
                mp:params.mp,
                extra_data: {},
                success: () => {

                },
                fail: () => {

                },
                cancel: () => {

                }
            })
        },

        reportData() {
            tracking.init();

            saUtils.init(pageKey);
            saUtils.update({
                jsbrnd: this.jsbrnd
            });
            saUtils.pv();
        },
    }
}
</script>
<style scoped lang="less">
.payment_box {
    padding: 35px 18px;
    .title {
        font-size: 34px;
        text-align: left;
        margin-bottom: 35px;
    }
    .pay_box {
        display: flex;
        justify-content: space-between;
        .item {
            width: 22.42%;
            height: 35px;
            line-height: 35px;
            color: #009BFF;
            background-color: #EFF2F5;
            border-radius: 4px;
            font-size: 17px;
            &.active {
                background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
                box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
                color: #fff;
            }
        }
    }
    .inter {
        margin-top: 20px;
        height: 58px;
        display: flex;
        align-items: center;
        position: relative;
        &:before{
            content: '元';
            font-size: 17px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
        }
        input{
            border: none;
            font-size: 17px;
        }
        input::-webkit-input-placeholder{
            color: #ddd;
        }
    }
    .textarea {
        h4 {
            font-size: 17px;
            text-align: left;
        }
        margin-top: 35px;
        align-items: center;
        padding-bottom: 20px;
        input{
            margin-top: 20px;
            width: 100%;
            border: none;
            font-size: 17px;
        }
        input::-webkit-input-placeholder{
            color: #ddd;
        }
    }
    .submit_btn {
        margin-top: 70px;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 4px 30px 0 rgba(0,155,255,0.40);
        border-radius: 4px;
        font-size: 18px;
        border: none;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px;
    }
    .green_doctor {
        margin-top: 60px;
        background: #F6F6F6;
        padding: 15px;
        border-radius: 5px;
        .dec {
            text-align: left;
            line-height: 1.5;
            font-size: 12px;
            span {
                color: #ED9F39;
            }
        }
        .select-box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            font-size: 11px;
            color: #aaa;
            i {
                display: inline-block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid #aaa;
                margin-right: 3px;
                &.select {
                    position: relative;
                    border: 1px solid #009BFF;
                    &:after {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #009BFF;
                        position: absolute;
                        left: 2px;
                        top: 2px;
                    }
                }
            }
            span {
                color: #009BFF;
            }
        }
    }
}
</style>
