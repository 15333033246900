<template>
  <div class="wrap-box">
      <div class="mock"></div>
      <div class="content">
          <div class="title">{{title}}</div>
          <div class="container" v-html="content"></div>
          <div class="agree_btn" @click="close">
            已完整阅读并同意
          </div>
      </div>
  </div>
</template>

<script>
import doc from './doc';
export default {
    name: 'agreement-dec',
    data() {
        return {
            title: '123',
            content: `<p>123</p>`
        }
    },
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    mounted() {
        const { title, content } = doc[this.type]
        this.title = title
        this.content = content
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="less">
.wrap-box {
    .mock {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0,0.50);
    }
    .content {
        width: 89.3%;
        height: 500px;
        position: absolute;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .title {
            font-size: 24px;
            font-weight: bold;
            padding: 31px 0 25px 0;
        }
        .container {
            height: 320px;
            overflow-y: scroll;
            text-align: left;
            padding: 0 20px;
            /deep/ p {
                margin: 5px 0;
            }
        }
    }
    .agree_btn {
        position: absolute;
        bottom: 20px;
        width: 89.3%;
        height: 50px;
        line-height: 50px;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 4px 30px 0 rgba(0,155,255,0.40);
        border-radius: 4px;
        opacity: 0.87;
        font-size: 17px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
    }
}
</style>
