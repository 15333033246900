import { mmcAjax } from '@/common/js/mmcModule';

export function perCheck({env, redirect_url, projuuid}) {
    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/pay/pre/check`,
        data: {
            env,
            redirect_url,
            project_uuid: projuuid
        },
        crossDomain: true,
    })
}
export function paySubmit(data) {

    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/pay/create/order`,
        data,
        crossDomain: true,
    })
}
